import PageTitle from '../../../components/shared/PageTitle';
import S01 from './S01';
import S02 from './S02';
import S03 from './S03';
import S04 from './S04';
import S05 from './S05';
import Outro from '../../../components/product/Outro';

const Water = () => {
  return (
    <div>
      <PageTitle title="풀무원투오 미니 워터팩" subtitle="건강한 물을 마시는 새로운 방법" />
      <S01 />
      <S03 />
      <S02 />
      <S04 />
      <S05 />
      <Outro title="투오로 변화하는 일상을 경험해 보세요." order={false} webUrl="http://www.pulmuone2o.com/" color="pure" />
    </div>
  );
};

export default Water;
