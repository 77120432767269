import classNames from 'classnames/bind';

import styles from './S01.module.scss';

import background from '../../../assets/images/products/tuo/s01/background.jpg';

const cx = classNames.bind(styles);

const S01 = () => {
  return (
    <div className={cx('container')} style={{ backgroundImage: `url(${background})` }}>
      <div className={cx('limiter')}>
        <h2 className={cx('title')}>나의 첫 워터팩 라이프</h2>
        <p className={cx('text')}>
          투오 미니 워터팩은 5중 레이어링 구조의 파우치에
          <br />
          &nbsp;내츄럴 미네랄 워터(천연광천수)를 담은 제품입니다.
        </p>
      </div>
    </div>
  );
};

export default S01;
