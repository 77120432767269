import classNames from 'classnames/bind';

import styles from './Download.module.scss';

const cx = classNames.bind(styles);

const Download = ({ title, names, files, color }) => {
  return (
    <div className={cx('download')}>
      <p className={cx('text')} style={{ color }}>
        {title.split('\\n').map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </p>
      <div className={cx('buttons')}>
        {files.map((file, index) => (
          <a key={index} href={file} target="_blank" rel="noreferrer" style={{ borderColor: color, color }}>
            {names[index]}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Download;
