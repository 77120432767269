import classNames from 'classnames/bind';

import styles from './S02.module.scss';
import product from '../../../assets/images/products/tuo/s02/product.jpg';
import Nutrient from '../../../components/product/Nutrient';
import Products from '../../../components/product/Products';
import Download from '../../../components/product/Download';

const cx = classNames.bind(styles);

const S02 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <Products products={[{ title: '투오 미니 워터팩 4.2L', image: product }]} color="#64aadd" />
        <Nutrient
          data={{
            head: ['칼슘(Ca)', '칼륨(K)', '불소(F)', '마그네슘(Mg)', '나트륨(Na)'],
            body: [['7.3 ~ 15.0', '0.4 ~ 0.5', '0.0 ~ 0.3', '1.2 ~ 1.7', '4.2 ~ 5.7']],
          }}
          color="#64aadd"
        />
        <Download
          title="투오 미니 워터팩은 풀무원의 엄격하고 깐깐한 품질 검사를 거쳐 생산됩니다.\n안심하고 드실 수 있습니다."
          names={['수질 검사 성적서']}
          files={['/static/report/Water_Report-Mini_Waterpack.pdf']}
          color="#64aadd"
        />
      </div>
    </div>
  );
};

export default S02;
